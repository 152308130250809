import {
  updateUserInfo
} from '@/services'
export default {
  tableItems: [
    {
      prop: 'id',
      label: 'ID',
      classname: 'text-blue'
    },
    {
      prop: 'name',
      label: 'Name',
      classname: ''
    },
    {
      prop: 'email',
      label: 'Email',
      width: '250px',
      classname: ''
    },
    {
      prop: 'role',
      label: 'Role',
      classname: ''
    },
    {
      prop: 'institution_name',
      label: 'Institution',
      classname: ''
    },
    {
      prop: 'active',
      label: 'Active',
      classname: '',
      isSwitch: true,
      methods: (id, active) => updateUserInfo(id, {user: {active}})
    }
  ],
  tableActions: [
    {
      label: 'View',
      url: '/users/details',
      type: 'jump',
      id: 'show'
    },
    {
      label: 'Edit',
      url: '/users/update',
      type: 'jump',
      id: 'update'
    }
  ],
  pageActions: [
    {
      label: 'Add',
      url: '/users/update',
      type: 'jump',
      id: 'create'
    }
  ]
}